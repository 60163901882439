.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  height: 250px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer a {
  font-size: 25px;
  color: white;
  transition: all 0.2s ease-in-out;
}
.footer a:hover {
  transform: scale(1.5);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: 20px 0 10px;
}

.footer-box h5 {
  font-size: 20px;
  font-weight: 500;
}

.footer-box h4 {
  font-size: 20px;
  font-weight: 500;
}
