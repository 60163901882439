.home-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container {
  position: relative;
  border: 2px solid black;
  width: 700px;
  height: 600px;
  padding: 20px;
  margin-top: 100px;
  border-radius: 30px;
  box-shadow: 0px 6px black;
  overflow: hidden;
}

.inputbox2,
.inputbox {
  position: relative;
  width: 250px;
  margin-top: 15px;
  margin-left: 60px;
}
.filter {
  margin-left: -20px;
  margin-top: -10px;
  margin-bottom: 70px;
  position: relative;
}

.inputbox2 .btn-filter {
  color: black;
}

.inputbox2 input,
.inputbox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: white;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
}

input[type="date"] {
  color: #e8e8e8;
}

.btn-date {
  z-index: 10;
}

::-webkit-calendar-picker-indicator {
  background-color: #e8e8e8;
}

.inputbox2 span,
.inputbox span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

.inputbox2 input:valid ~ span,
.inputbox2 input:focus ~ span,
.inputbox input:valid ~ a,
.inputbox input:focus ~ a,
.inputbox input:valid ~ span,
.inputbox input:focus ~ span {
  color: black;
  transform: translateX(-10px) translateY(-34px);
  font-size: 0, 75em;
}

.inputbox2 input:valid ~ span::after,
.inputbox2 input:focus ~ span::after {
  position: absolute;
  content: "";
  right: 1px;
  width: 50px;
  height: 25px;
  background-color: #e8e8e8;
}
.inputbox2 i,
.inputbox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 110%;
  height: 2px;
  background: black;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputbox2 input:valid ~ i,
.inputbox2 input:focus ~ i,
.inputbox input:valid ~ i,
.inputbox input:focus ~ i {
  height: 44px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 50px;
  margin-bottom: 40px;
}
.content textarea {
  font-size: 1em;
  background-color: black;
  color: white;
  padding: 10px;
}

/* ================= BUTTON =============== */
.buttonbox {
  display: flex;
  justify-content: center;
}
button {
  /* Variables */
  --button_radius: 0.75em;
  --button_color: #e8e8e8;
  --button_outline_color: #000000;
  font-size: 17px;
  font-weight: bold;
  border: none;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
}

.button_top {
  display: block;
  box-sizing: border-box;
  border: 2px solid var(--button_outline_color);
  border-radius: var(--button_radius);
  padding: 0.75em 1.5em;
  background: var(--button_color);
  color: var(--button_outline_color);
  transform: translateY(-0.2em);
  transition: transform 0.1s ease;
}

button:hover .button_top {
  /* Pull the button upwards when hovered */
  transform: translateY(-0.33em);
}

button:active .button_top {
  /* Push the button downwards when pressed */
  transform: translateY(0);
}

.section1 {
  display: flex;
  flex-direction: row;
}

.img-box img {
  width: 300px;
  margin: 25px 0 0 50px;
}

/* ============================ MEDIA QUERIES (EXTRA SMALL DEVICES) ============================ */
@media screen and (max-width: 768px) {
  .img-box {
    display: none;
  }
  .inputbox {
    width: 80%;
    margin: 15px 15px;
  }
  .content {
    width: 90%;
    margin: 15px 15px;
  }
  .form-container {
    margin: 150px 25px;
  }
  .section1 {
    flex-direction: column;
  }
  .buttonbox {
    margin-top: 50px;
  }
  .home-container {
    height: auto;
  }
}
