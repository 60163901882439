.header-container {
  position: fixed;
  background-color: #000000;
  height: 80px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.navbar {
  display: flex;
  justify-content: space-between;
  max-width: 1024px;
  width: 100%;
}
.navbar a {
  margin: 0 30px;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .navbar a {
    font-size: 18px;
    margin: 0 15px;
  }
}
