@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
body {
  background-color: #e8e8e8;
}

html {
  scroll-behavior: smooth;
}
